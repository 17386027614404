import React from 'react';
import { createRoot } from 'react-dom/client';
import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';

import ConfigWrapper from './components/utils/ConfigWrapper';

import './index.css';

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    background: {
      default: '#212121',
    },
  },
});

const App = React.lazy(() => import('./App'));

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <ConfigWrapper>
      <App />
    </ConfigWrapper>
  </ThemeProvider>,
);
