import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';

import FullScreenLoading from '../FullScreenLoading';

const ConfigWrapper = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('/config.json')
      .then((res) => res.json())
      .then((config) => {
        window.config = config;
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return (
      <FullScreenLoading>
        <Typography>Ladataan asetuksia..</Typography>
      </FullScreenLoading>
    );

  return children;
};

export default ConfigWrapper;
