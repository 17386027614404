import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

const FullScreenLoading = ({ children }) => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    direction="column"
    style={{ minHeight: '100vh' }}
  >
    <Grid item mb={2}>
      <CircularProgress />
    </Grid>
    <Grid item>{children}</Grid>
  </Grid>
);

export default FullScreenLoading;
